import React, { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
//import Nav from 'react-bootstrap/Nav';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "./PinPoint-Logo.svg";
import "./NavPanel.css";
import WebFont from 'webfontloader';

function NavPanel() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Manrope', 'sans-serif']
      }
    });
   }, []);

   return (
    <Navbar className="Nav-bar" bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="#home">
          <img src={logo} className="App-logo" alt="logo" />
          <span className='company-name'>PinPoint Cloud</span>
        </Navbar.Brand>
        {/* 
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Link</Nav.Link>
            <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        */}
      </Container>
    </Navbar>
  );
}

export default NavPanel;