import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactForm from "./ContactForm";
import NavPanel from "./NavPanel";

import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import background from "./mesh_background.jpg";
import iot1 from "./iot-1.jpg";
//import iot2 from "./iot-2.jpg";
import WebFont from 'webfontloader';

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Manrope', 'sans-serif']
      }
    });
   }, []);
   
   return (
    <div className="App">
      <NavPanel />
      <div className='App-main'  style={{ backgroundImage: `url(${background})`}}>
        <Container fluid>
          <Row>
            <Col sm={12} md={{span: 7, offset: 1}}>
              <Container className='App-header-image-container'>
                <img src={iot1} className="App-header-image" alt="iot" />
              </Container>
              <Container className='App-header-title'>
                <h1>Every Device Has a Story to Tell</h1>
              </Container>
            </Col>
            <Col className='App-contact' sm={12} md={3}>
              <h2>We can help you to hear their story</h2>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default App;