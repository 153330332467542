import React, { useState } from "react";
import { Form, Button, Container} from "react-bootstrap";
import './ContactForm.css';

const FORM_ENDPOINT = "https://api.emailjs.com/api/v1.0/email/send";

const ContactForm = () => {
  const [status, setStatus] = useState('');

  const handleSubmit = (e) => {
      e.preventDefault();

      // Anything you need to inject dynamically
      // const injectedData = {
      //     DYNAMIC_DATA_EXAMPLE: 123,
      // };
      const inputs = e.target.elements;
      let params = {
        user_id: 'KntwJzYxXyazBgsgR',
        service_id: 'pinpoint-mailgun',
        template_id: 'pinpoint_webmessage',
        template_params: {}
      };

      for (let i = 0; i < inputs.length; i++) {
          if (inputs[i].name) {
              params.template_params[inputs[i].name] = inputs[i].value;
          }
      }
      // Object.assign(data, injectedData);

      fetch(FORM_ENDPOINT, {
          method: "POST",
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
      })
      .then((response) => {
          // It's likely a spam/bot request, so bypass it to validate via captcha
          // if (response.status === 422) {
          //   Object.keys(injectedData).forEach((key) => {
          //       const el = document.createElement("input");
          //       el.type = "hidden";
          //       el.name = key;
          //       el.value = injectedData[key];

          //       e.target.appendChild(el);
          //   });

          //   e.target.submit();
          //   throw new Error("Please finish the captcha challenge");
          // }

          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
      })
      .then(() => setStatus("We'll be in touch soon."))
      .catch((err) => setStatus(err.toString()));
  };

  if (status !== '') {

      return (
        <Container className='Thank-you'>
            <div className="text-2xl">Thank you!</div>
            <div className="text-md">{status}</div>
        </Container>
      );

  } else {

    return (
      <Form
        className='ContactForm'
        onSubmit={handleSubmit}
        method="POST"
        target="_blank"
      >
        <Form.Group className="mb-4" controlId="formContactName">
          <Form.Control 
            type="text"
            placeholder="Your name"
            name="name"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="formContactEmail">
          <Form.Control 
            type="email"
            placeholder="Your email"
            name="email"
            required
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="formContactMessage">
          <Form.Control
            as="textarea"
            rows={6}
            placeholder="Your message"
            name="message"
            required
          />
        </Form.Group>
        <Button
          className='ContactForm-submit'
          type="submit"
        >
          Send a message
        </Button>
      </Form>
    );

  }
};

export default ContactForm;